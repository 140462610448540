@import 'minireset.css/minireset.sass';
@import './theme/theme.scss';

/* stylelint-disable selector-id-pattern */
#🎯 {
  position: absolute;
  width: 100%;
}
/* stylelint-enable selector-id-pattern */

body {
  overflow-x: hidden; /* to prevent horizontal scrollbar */
  font-family: $content-font;
  color: $primary;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $primary;
  text-decoration: none;

  &:focus-visible {
    outline: $focus-outline;
  }
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: $title-font;
  font-weight: 700;
}

h5,
h6,
p,
li,
::placeholder {
  font-family: $content-font;
}

h1,
.h1 {
  font-size: 26px;

  @include media('md') {
    font-size: 30px;
  }
}

h2,
.h2 {
  font-size: 20px;

  @include media('md') {
    font-size: 24px;
  }
}

h3,
.h3 {
  font-size: 18px;

  @include media('md') {
    font-size: 20px;
  }

  color: $secondary;
}

h4,
.h4 {
  font-size: 16px;

  @include media('md') {
    font-size: 18px;
  }
}

h5,
.h5 {
  font-size: 15px;

  @include media('md') {
    font-size: 16px;
  }
}

p {
  font-size: 16px;
}

// Prevent iOS zoom on input focus.
// Stackoverflow discussion: https://stackoverflow.com/a/6394497/14730311
input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

.pointer-cursor {
  cursor: pointer;
}

@-moz-document url-prefix('') {
  .glider-track {
    margin-bottom: 18px;
  }

  .glider-wrap {
    overflow: hidden;
  }
}

.glider-contain {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.glider {
  position: relative;
  margin: 0 auto;
  overflow-y: hidden;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.glider-track {
  z-index: 100;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
  transform: translateZ(0);
  scrollbar-width: none;
}

.glider.draggable {
  cursor: grab;
  user-select: none;
}

.glider.draggable .glider-slide img {
  pointer-events: none;
  user-select: none;
}

.glider.drag {
  cursor: grabbing;
}

.glider-slide {
  align-content: center;
  justify-content: center;
  width: 100%;
  min-width: 150px;
  user-select: none;
}

.glider-slide img {
  max-width: 100%;
}

.glider::-webkit-scrollbar {
  height: 0;
  opacity: 0;
}

.glider-prev,
.glider-next {
  position: absolute;
  top: 30%;
  left: -13px;
  z-index: 2;
  padding: 0;
  font-size: 40px;
  line-height: 1;
  color: #666;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  background: none;
  border: 0;
  outline: none;
  opacity: 1;
  transition:
    opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67),
    color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.glider-prev:hover,
.glider-next:hover,
.glider-prev:focus,
.glider-next:focus {
  color: #a89cc8;
}

.glider-next {
  right: -13px;
  left: auto;
}

.glider-next.disabled,
.glider-prev.disabled {
  color: #666;
  cursor: default;
  opacity: 0.25;
}

.glider-hide {
  opacity: 0;
}

.glider-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 30px auto 0;
  user-select: none;
}

.glider-dot {
  display: block;
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 7px;
  color: transparent;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: solid $primary 1px;
  border-radius: 999px;
  outline: none;
}

.glider-dot:hover,
.glider-dot:focus,
.glider-dot.active {
  background: $primary;
}

@media (width <= 36em) {
  .glider::-webkit-scrollbar {
    display: none;
    width: 7px;
    height: 3px;
    opacity: 1;
  }

  .glider::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 99px;
    box-shadow: 0 0 1px transparent;
    opacity: 1;
  }
}

:root {
  --matrix-glyph-size: 15px;
  --matrix-glyph-font-size: 15px;
  --matrix-glyph-front-color: rgb(255 255 255 / 80%);
  --matrix-glyph-tail-color: #0f0;
  --matrix-overlay-color: rgb(0 0 0 / 12%);
}
