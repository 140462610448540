$navbar-height: 100px;
$page-width: 1200px;
$box-shadow:
  0 4px 6px -1px rgb(0 0 0 / 10%),
  0 2px 4px -2px rgb(0 0 0 / 10%);
$focus-outline: 2px dashed blue;

// COMPONENTS VARIABLES

// JOB SIDEBAR
$job-page-sidebar-min-width: 300px;

// AI SUMMARY
$ai-summary-width: 100%;
$ai-summary-min-height: 300px;
$ai-summary-border-radius: 16px;
